.d-contact {
  display: flex;
  flex-direction: column;

  .contact-page {
    z-index: 1;
  }

  .contact-body {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 25%;
    margin-top: 1rem;
    @media (max-width: 1140px) {
      padding: 5rem 0;
      height: 100%;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10rem;
    }
    h1 {
      color: #efefe7;
      text-align: center;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: #efefe7;
      text-align: center;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .contact-header {
      margin-bottom: 70px;
    }
  }

  form {
    border-radius: 2.0625rem;
    background: #222;

    box-shadow: 0px 0px 84.6px 10px rgba(0, 0, 0, 0.26);
    padding: 4rem 5rem;
    @media (max-width: 768px) {
      padding: 2.5rem;
    }
    width: 75%;
    @media (max-width: 768px) {
      width: 80%;
    }

    text-align: left;
    .form-group {
      margin-bottom: 2rem;
      padding: -1rem 5rem 0rem 5rem;
      @media (max-width: 768px) {
        margin-bottom: 1rem;
        padding: 0rem 0rem 0rem 0rem;
      }
    }
    label {
      color: #efefe7;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0.5rem;
    }
    .form-control {
      font-size: 15px;
      border-radius: 3rem;
      border: 3px solid #efefe7;
      background: #202020;
      box-shadow: 0px 0px 13.6px 2px rgba(0, 0, 0, 0.13);
      color: #efefe7;
    }
    .form-control::placeholder,
    select {
      color: #565656;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-control:focus {
      border-radius: 1.0625rem;
      border: 3px solid #efefe7;
      background: #202020;
      box-shadow: 0px 0px 13.6px 2px rgba(0, 0, 0, 0.13);
      color: #efefe7;
    }
    .form-button {
      display: flex;
      width: 9.1875rem;
      height: 4.9375rem;
      //padding-right: 3px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 2rem;
      border: 2px solid #efefe7;
      background: #202020;
      color: #efefe7;
      margin: 0 auto;

      color: #efefe7;
      text-align: center;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }
  }
}
