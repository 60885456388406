@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

#root {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: Inter, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
