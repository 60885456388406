.header {
  max-width: 100%;
  align-items: center;
  position: absolute;
  .text-start {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
}

#header-logo {
  width: 6rem;
}
