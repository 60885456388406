.home-video {
  max-width: 100%;
  pointer-events: none;
}

.down-arrow-container {
  @media screen and (max-width: 1200px) {
    display: none; /* Hide the entire container on screens smaller than 992px */
  }
  .button-obj {
    display: contents;
  }
  .down-arrow {
    height: 5rem;
    position: relative;
    top: -30vh;
  }
}
