.row {
  width: 100%;
}

.video-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 3rem;
  transition: border-radius 0.2s ease;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 4rem;
}

.video-item:hover {
  opacity: 0.6;
  border-radius: 0%;
  transition: border-radius 0.2s ease;
  .play-button-overlay {
    opacity: 1;
  }
}

.modal-css {
  max-width: 90% !important;
  width: 90%;
  max-height: 90% !important;
  height: 90%;
  margin: auto;

  .modal-content {
    height: 100%;

    .modal-header {
      background-color: black;
      color: white;
      border-bottom: none;
      button {
        filter: invert(1);
      }
    }

    .modal-body {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      iframe {
        width: 80rem;
        height: 45rem;
        border: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal-fullscreen-md-down {
    max-width: 100% !important;
    width: 100%;
    max-height: 100% !important;
    height: 100%;
  }
}

.video-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 5px;
  text-align: center;
  font-size: 0.9rem;
  h4 {
    text-shadow: 0 0 7px black;
  }
}

/* From Uiverse.io by JaydipPrajapati1910 */
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 12.5rem;
  height: 5rem;
  box-sizing: border-box;
}

.button-cover {
  height: 6.25rem;
  margin: 1.25rem;
  background-color: #fff;
  // box-shadow: 0 0.62rem 1.25rem -0.5rem #c5d6d6;
  border-radius: 0.25rem;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: white;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.31rem;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 4.63rem;
  height: 2.25rem;
  margin: -1.25rem auto 0 auto;
  overflow: hidden;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: white;
  transition: 0.3s ease all;
  z-index: 1;
}

.button.r,
.button.r .layer {
  border-radius: 6.25rem;
}

#button-3 .knobs:before {
  content: "VFX";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  min-width: 1.8rem;
  color: #fff;
  font-size: 0.625rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 0.56rem 0.25rem;
  background-color: black;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox:active + .knobs:before {
  width: 2.88rem;
  border-radius: 6.25rem;
}

#button-3 .checkbox:checked:active + .knobs:before {
  margin-left: -1.63rem;
}

#button-3 .checkbox:checked + .knobs:before {
  content: "2D";
  left: 2.625rem; /* 42px -> 2.625rem */
  background-color: black;
}
