.App {
  text-align: center;
  background-color: #101010;
  font-family: Inter;
}


.header { 
  top: 0;
  left: 0;     
  width: 100vw;
  z-index: 1000;
  background-color: transparent;
}

/* Width */
::-webkit-scrollbar {
  width: 0.4rem;
}

/* Oval Handle */
::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5rem;
}

/* Black Track */
::-webkit-scrollbar-track {
  background-color: #080808;
}
