.footer {
  max-width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  //margin-top: 3rem;
  z-index: 1;
  .logo {
    width: 5rem;
  }
}
